<template>
  <button class="button">
    <slot />
  </button>
</template>
<style lang="scss" scoped>
.button {
  position: relative;

  width: var(--button-action-width);
  height: 100%;

  display: grid;
  place-content: center;

  color: var(--c-primary);
  cursor: pointer;

  :deep(svg) {
    width: 24px;
    height: 24px;
  }

  &.hover {
    @include breakpoint(ds) {
      @include transitions(background-color);
      height: var(--button-action-width);
    }
    @include hover {
      background-color: var(--c-blue-light-40);
    }
  }
}
</style>
